import React, { useState } from 'react'; 
import logo from '../Images/logo.png';
import logo2 from '../Images/logo2.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import { Link } from 'react-router-dom';

function Navbar() {

  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar navbar-expand-md">
      <div className="container-fluid">

        <Link className="navbar-brand logo" to="/">
          <img src={logo2} alt="Logo" />
        </Link>


        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          onClick={toggleNavbar}
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded={isOpen}
          aria-label="Toggle navigation"
        >
         

          {isOpen ? (
          <span className="bi bi-x" style={{fontSize: '1.5em', fontWeight: 'bold'}}></span> // Close icon
          
        ) : (
          <span className="navbar-toggler-icon hamburger-icon"></span> // Hamburger icon
        )}

        </button>

        <div className="collapse navbar-collapse justify-content-end gap-3 custom-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link" to="/">Home</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/About">About</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/Menu">Menu</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="#">order online</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/Contact">Contact</Link>
            </li>
            
            {/* <li className="nav-item">
              <Link className="nav-link" to="https://rassoi.friendzbazzar.com/">Login</Link>
            </li> */}

          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
