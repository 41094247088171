import React from 'react'
import { Link } from 'react-router-dom';
import logo from '../Images/logo.png'

function Login() {
    return (
        <div className='loginpage'>

            <div className='container'>
                <div className='row'>
                <Link className="logoLog pb-0 m-0" to="/">
                    <img src={logo} alt="Logo" />
                </Link>
                </div>
            </div>
                <div className="section">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 text-center align-self-center pb-5">
                                <div className="section pb-5 text-center">
                                    <div className="card-3d-wrap mx-auto">
                                        <div className="card-3d-wrapper">
                                            <div className="card-front">
                                                <div className="center-wrap">
                                                    <div className="section text-center">
                                                        <h4 className="mb-4 pb-3">Log In</h4>
                                                        <div className="form-group login-form-group">
                                                            <input type="email" name="logemail" className="form-style login-form-style" placeholder="Your Email" id="logemail" autocomplete="off" />
                                                            <i className="input-icon uil uil-at"></i>
                                                        </div>
                                                        <div className="form-group mt-2">
                                                            <input type="password" name="logpass" className="form-style login-form-style" placeholder="Your Password" id="logpass" autocomplete="off" />
                                                            <i className="input-icon uil uil-lock-alt"></i>
                                                        </div>
                                                        <Link to="#" className="btn mt-4 loginbtn">submit</Link>
                                                        <p className="mb-0 mt-4 text-center"><Link to="#0" className="link">Forgot your password?</Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
        </div>
    )
}

export default Login;
