import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL } from '../config';

import { REACT_APP_CONTENT_URL } from '../config.js';
import Header from '../CommonPages/Header.js';
import Footer from '../CommonPages/Footer.js';
import Aboutbanner from '../Images/abtBan.jpg';

function About() {
  const [banner, setBanner] = useState([]);
  const [aboutContent, setAboutContent] = useState([]);

  useEffect(() => {
    const fetchContentData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/page-content/category/3`);
        if (response.data) {
          setAboutContent(response.data); 
        }
      } catch (error) {
        console.error(error);
      } 
    };

    fetchContentData();
  }, []);

  const fetchBanner = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/Baanner`);
        setBanner(response.data);
    } catch (error) {
        console.log(error);
    }
};

useEffect(() => {
  fetchBanner();
}, []);

  const getImageUrl = (imagePath) => {
    if (imagePath) {
      const fixedImagePath = imagePath.replace(/\\/g, '/');
      return `${REACT_APP_CONTENT_URL}/${fixedImagePath}`;

    }
    return Aboutbanner; 

  
  };

  return (
    <>
      <Header />
      <div className='Aboutpage mt-5 pt-3'>
        <div>
        <div className='RasoiBanner'>
                    <img src={banner.length > 0 ? getImageUrl(banner[0].image) : Aboutbanner} alt='image' />
                    <h1 className='text-uppercase' style={{ color: '#FEA78B' }}>ABOUT US</h1>
                </div>

          {aboutContent.length > 0 ? ( 
            aboutContent.map((item, index) => (
              <div className='container' key={item.id}>
                <div className='row my-5' style={{ display: 'flex', alignItems: 'center' }}>
                  {index % 2 === 0 ? (
                    <>
                      <div className='col-md-6'>
                        <div className='homepage-imageBox'>
                          <img src={getImageUrl(item.image)} alt={item.title} />
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='text-center p-5'>
                          <div className='pb-3'><h5 className='oval mx-auto'>{item.title}</h5></div>
                          <p className='p-3' style={{ borderBottom: '1px solid #003744' }}>{item.description}</p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='col-md-6'>
                        <div className='text-center p-5'>
                          <div className='pb-3'><h5 className='oval mx-auto'>{item.title}</h5></div>
                          <p className='p-3' style={{ borderBottom: '1px solid #003744' }}>{item.description}</p>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='homepage-imageBox'>
                          <img src={getImageUrl(item.image)} alt={item.title} />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div>No content available.</div>
          )}

        </div>
      </div>
      <Footer />
    </>
  );
}

export default About;
