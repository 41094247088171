import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../config';
import 'bootstrap-icons/font/bootstrap-icons.css';
import axios from 'axios';

const Footer = () => {
  const [settings, setSettings] = useState({});

  useEffect(() => {
    axios.get(`${BASE_URL}/settings`)
      .then((response) => {
        console.log(response.data);
        if (response && Array.isArray(response.data)) {
        
          const rasoiSettings = response.data.find(item => item.website.includes("Rasoi"));
          if (rasoiSettings) {
            setSettings(rasoiSettings); 
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <>
      <div>
        <div className="">
          <footer>
            <div className="footer links wid">
              <ul>
                <li><h5>ABOUT US</h5></li>
                <li>{settings.description || 'Loading...'}</li> 
                <li>
                  <div className="footer icons d-flex">
                    <div className="footer-icons-container">
                      {settings.facebook && (
                        <a href={settings.facebook} target='_blank' rel="noopener noreferrer">
                          <i className="bi bi-facebook" />
                        </a>
                      )}
                      {settings.twitter && (
                        <a href={settings.twitter} target='_blank' rel="noopener noreferrer">
                         <i class="bi bi-twitter-x" />
                        </a>
                      )}
                      {settings.instagram && (
                        <a href={settings.instagram} target='_blank' rel="noopener noreferrer">
                          <i className="bi bi-instagram" />
                        </a>
                      )}
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div className="footer links">
              <ul>
                <li><h5>QUICK LINKS</h5></li>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/About">About Us</Link></li>
                <li><Link to="/Menu">Menu</Link></li>
                {/* <li><Link to="#">Order Online</Link></li> */}
              </ul>
            </div>

            <div className="footer head">
              <ul>
                <li><h5>SUPPORT</h5></li>
                {/* <li><a href="#">FAQ</a></li> */}
                {/* <li><a href="#">Privacy Policy</a></li> */}
                <li><Link to="/Contact">Contact Us</Link></li>
                <li><Link to="#">Order Online</Link></li>

              </ul>
            </div>

            <div className="footer contact wid">
              <ul>
                <li><h5>INFO</h5></li>
                <li>{settings.address || 'loading..'}</li>
                <li><i className="bi bi-telephone"></i> {settings.contact || 'loading..'}</li>
                <li><i className="bi bi-envelope-at"></i> {settings.email || 'loading..'}</li>
              </ul>
            </div>
          </footer>
        </div>
        <h6 id="Copyright" className='text-center py-3' style={{ backgroundColor: '#FFFBF5', paddingLeft: 8, marginBottom: '0', padding: 6, fontSize: 'small' }}>
          © Copyright 2024 | Developed by - CRIMSONS SYSTEMS | All Rights Reserved
        </h6>
      </div>
    </>
  );
};

export default Footer;
