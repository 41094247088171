import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../config';
import { REACT_APP_IMG_URL } from '../config';
import { REACT_APP_CONTENT_URL } from '../config';
import Aboutbanner from '../Images/contactt.png';
import Header from '../CommonPages/Header';
import Footer from '../CommonPages/Footer';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contactNo: '', 
    message: '',
  });

  const [submissionStatus, setSubmissionStatus] = useState('');
  const [banner, setBanner] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${BASE_URL}/contact`, formData)
      .then((response) => {
        setSubmissionStatus('Message sent successfully!');
        console.log('Form submitted:', response.data);

        setFormData({
          name: '',
          email: '',
          contactNo: '',  
          message: '',
        });
      })
      .catch((error) => {
        setSubmissionStatus('Failed to send the message. Please try again.');
        console.error('There was an error!', error);
      });
  };

  const fetchBanner = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/Baanner`);
        setBanner(response.data);
    } catch (error) {
        console.log(error);
    }
};

useEffect(() => {
  fetchBanner();
}, []);

  const getImageUrl = (imagePath) => {
    if (imagePath) {
      const fixedImagePath = imagePath.replace(/\\/g, '/');
      return `${REACT_APP_CONTENT_URL}/${fixedImagePath}`;
    }
    return Aboutbanner;
  };

  return (
    <>
      <Header />
      <div className="contactpage mt-5 pt-3">

      <div className='RasoiBanner'>
                    <img src={banner.length > 0 ? getImageUrl(banner[2].image) : Aboutbanner} alt='image' />
                    <h1 className='text-uppercase' style={{ color: '#FEA78B' }}>CONTACT US</h1>
                </div>

        <div className="container mt-5 mx-auto">
          <div className="row mx-5 px-5 contact-margin-padding">
            <h1 className="mb-3 pb-2 bold">Get in Touch</h1>
            <p>
              Let us know about your dining experience, thoughts, ideas, and anything you think we
              can do to make your next experience at Rasoi, even better. Fill out our feedback form
              below and let us know.
            </p>
            <div className="contact-form px-5 mb-5">
              <form onSubmit={handleSubmit}>
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Your name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />

                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Your email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />

                <label htmlFor="contactNo">Contact</label>
                <input
                  type="text"
                  id="contactNo"  
                  name="contactNo"  
                  placeholder="Contact number"
                  value={formData.contactNo}  
                  onChange={handleChange}
                  required
                />

                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  placeholder="Write your message here..."
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>

                <button type="submit">Send Message</button>
              </form>
              {submissionStatus && <p className='pt-2 mt-2' style={{ color: submissionStatus.includes('Message sent successfully!') ? 'green' : 'red' }}>{submissionStatus}</p>}

            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Contact;
