import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import { BASE_URL } from "../config";
import { REACT_APP_IMG_URL } from '../config.js';
import { REACT_APP_CONTENT_URL } from '../config.js';
import Header from "../CommonPages/Header.js";
import Footer from "../CommonPages/Footer.js";
import Aboutbanner from "../Images/about.png";
import chicken from "../Images/chicken.png";

function Menu() {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [menuItems, setMenuItems] = useState([]);
  const [Banner, setBanner] = useState([]);

  useEffect(() => {
    fetchSubcategories();
    fetchBanner();
  }, []);

  const fetchSubcategories = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/subcategories`);
      const data = response.data;

      const transformedOptions = data.map((item) => ({
        value: item.id,
        label: item.name,
      }));

      setOptions(transformedOptions);

      if (transformedOptions.length > 0) {
        setSelectedOption(transformedOptions[0]);
        fetchMenuItemscatagery(transformedOptions[0].value);
      }
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

  const fetchBanner = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/Baanner`);
      setBanner(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectChange = (selected) => {
    setSelectedOption(selected);
    fetchMenuItemscatagery(selected.value);
  };

  const fetchMenuItemscatagery = async (categoryId) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/products/subcategories/${categoryId}`
      );
      setMenuItems(response.data);
      console.log("Response data", response.data);
    } catch (error) {
      console.error("Error fetching menu items:", error);
    }
  };

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "100%",
    }),
    menu: (provided) => ({
      ...provided,
      width: "100%",
      zIndex: 9999,
    }),
    control: (provided) => ({
      ...provided,
      width: "100%",
    }),
  };

  const getImageUrl = (imagePath) => {
    if (imagePath) {
      const fixedImagePath = imagePath.replace(/\\/g, "/");
      return `${REACT_APP_CONTENT_URL}/${fixedImagePath}`;
    }
    return Aboutbanner;
  };

  const getProImageUrl = (imgPath) => {
    if (imgPath) {
      const fixedImagePath = imgPath.replace(/\\/g, "/");
      return `${REACT_APP_IMG_URL}/${fixedImagePath}`;
    }
    return Aboutbanner;
  };

  return (
    <>
      <Header />
      <div className="MenuPage mt-5 pt-3">
        <div className="RasoiBanner">
          <img
            src={Banner.length > 0 ? getImageUrl(Banner[1].image) : Aboutbanner}
            alt="image"
          />
          <h1 className="text-uppercase" style={{ color: "#FEA78B" }}>
            MENU
          </h1>
        </div>

        <div className="container menu-container">
          <div className="row my-5">
            <div className="col-12 col-md-6 col-lg-4">
              <Select
                value={selectedOption}
                onChange={handleSelectChange}
                options={options}
                styles={customStyles}
                placeholder="Select Food Menu"
                isLoading={options.length === 0}
              />
            </div>
          </div>

          <div className="row mt-3 py-3">
            {menuItems.length > 0 ? (
              menuItems.map((item) => (
                <div className="col-md-4 mb-5" key={item.id}>
                  <div
                    className="card menucard h-100"
                    style={{ width: "18rem", overflow: "hidden" }}
                  >
                    <img
                      src={getProImageUrl(item.image) || chicken}
                      className="card-img-top"
                      style={{ height: "14rem" }}
                      alt={item.name}
                    />
                    <div
                      className="card-body d-flex flex-column"
                      style={{ backgroundColor: "#003744", color: "#FEA78B" }}
                    >
                      <div className="flex-grow-1">
                        <h6 className="card-title text-wrap text-uppercase bold">
                          {item.name}
                        </h6>
                        <h6 className="card-text" style={{ fontSize: "15px" }}>
                          {item.description}
                        </h6>
                        <p className="line-clamp" style={{ fontSize: "13px" }}>
                          {item.details}
                        </p>
                      </div>
                      <Link
                        className="btn btn-outline-light mt-auto"
                        to="#"
                        style={{ cursor: "default" }}
                      >
                        SAR {item.price}
                      </Link>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No menu items available for this category.</p>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Menu;
